<template>
    <div>
        <CRow>
            <CCol sm="12" md="12">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol sm="6">
                                <h4 class="card-title mb-0">{{title}}</h4>
                            </CCol>
                              <CCol sm="6">
                                <div class="float-right" >
                                    <CButton  color="success" class="mr-3">
                                        Import Quiz
                                    </CButton>
                                    <CButton color="primary">
                                        New Worksheet
                                    </CButton>  
                                </div>
                                 
                            </CCol>
                            
                        </CRow>
                        <CRow class="mt-4">
                            <CCol sm="4">
                                <CSelect
                                    label="Subject"
                                    :options="subjects"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CSelect
                                    label="Topics"
                                    :options="topics"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CSelect
                                    label="Sub-Topics"
                                    :options="sub_topics"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CSelect
                                    label="Levels"
                                    :options="levels"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CSelect
                                    label="Difficulty"
                                    :options="difficulty_levels"
                                />
                            </CCol>
                            <CCol sm="4">
                                 <CInput label="Keyword"/>
                            </CCol>
                        </CRow>
                        <CRow>
                             <CCol sm="12" class="d-none d-md-block">
                                <div class="float-right">
                                    <CButton  color="info" class="mr-2">
                                        Reset
                                    </CButton>
                                    <CButton  color="success" @click="searchWS">
                                        Search
                                    </CButton>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow class="mt-4" v-if="showWorksheets">
                            <CCol col="12">
                                <CDataTable
                                    hover
                                    striped
                                    :items="worksheets"
                                    :fields="worksheet_fields"
                                    :active-page="activePage"
                                    :items-per-page="3"
                                    :pagination="{ doubleArrows: false, align: 'center'}"
                                    @page-change="pageChange"
                                >
                                    <template #difficulty_level="{item}">
                                        <td>
                                            <span v-for="index in (item.difficulty_level*1)" :key="index">
                                              * 
                                            </span>
                                        </td>
                                    </template>
                                    <template #actions="{item}">
                                        <td>
                                            <CLink to="#" class="mr-3">
                                                Edit
                                            </CLink>
                                            <CLink to="#">
                                                Delete
                                            </CLink>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>

                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    name:'Worksheets',
    data(){
        return{
            showWorksheets:false,
            activePage: 1,
            worksheets:[
                { id: 1, title: 'Primary 6 –Algebra 1', level: 'P6', subject:'Math', topic:'Algebra', subtopic:'', difficulty_level:'3' },
                { id: 2, title: 'Primary 3 –Multiplication', level: 'P3', subject:'Math', topic:'Multiplication', subtopic:'', difficulty_level:'4' },
                { id: 3, title: 'Primary 4 –Fractions 1', level: 'P4', subject:'Math', topic:'Fraction', subtopic:'', difficulty_level:'2' },
                { id: 4, title: 'Primary 1 –Fractions 1', level: 'P1', subject:'Math', topic:'Fraction', subtopic:'', difficulty_level:'3' },
                { id: 5, title: 'Primary 2 –Algebra 1', level: 'P2', subject:'Math', topic:'Algebra', subtopic:'', difficulty_level:'1' }

            ],
            worksheet_fields:[
                { key: 'id', label: 'S/No' },
                { key: 'title', label: 'Title' },
                { key: 'level', label: 'Level' },
                { key: 'subject', label: 'Subject' },
                { key: 'topic', label: 'Topic' },
                { key: 'subtopic', label: 'Subtopic' },
                { key: 'difficulty_level', label: 'Difficulty' },
                { key: 'actions', label: 'Actions' }
            ],
            title:"Worksheets",

            subjects:[
                    { 
                        value: 'Math', 
                        label: 'Math'
                    },
                    { 
                        value: 'English', 
                        label: 'English'
                    },
                    { 
                        value: 'Science', 
                        label: 'Science'
                    }
            ],
            topics:[
                    { 
                        value: 'Algebra', 
                        label: 'Algebra'
                    },
                    { 
                        value: 'Multiplication', 
                        label: 'Multiplication'
                    },
                    { 
                        value: 'Fractions', 
                        label: 'Fractions'
                    }
            ],
            sub_topics:[
                    { 
                        value: '-', 
                        label: '-'
                    },
            ],
            levels:[
                { 
                    value: 'p1', 
                    label: 'Primary 1'
                },
                { 
                    value: 'p2', 
                    label: 'Primary 2'
                },
                { 
                    value: 'p3', 
                    label: 'Primary 3'
                },
                { 
                    value: 'p4', 
                    label: 'Primary 6'
                },
                { 
                    value: 'p5', 
                    label: 'Primary 5'
                },
            ],
            difficulty_levels:[
                  { 
                    value: '1', 
                    label: '*'
                 }, 
                { 
                    value: '2', 
                    label: '**'
                 }, 
                { 
                    value: '3', 
                    label: '***'
                 },  
            ],
            filter:{
                subject:'',
                topic:'',
                sub_topic:'',
                difficulty_level:'',
                keyword:''
            },

        }
    },
    watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
    methods:{
        pageChange (val) {
            this.$router.push({ query: { page: val }})
        },
        searchWS()
        {
            this.showWorksheets = true
        }
    }
}
</script>